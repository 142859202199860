import { ArticleCardProps } from 'common/components/sharedLibrary'
import _ from 'lodash'

interface ArticlesToDisplay {
    featured: ArticleCardProps[]
    list: ArticleCardProps[]
}

export const getArticles = (
    { featured, list }: ArticlesToDisplay,
    category: string | null
): { featured: ArticleCardProps; list: ArticleCardProps[] } => {
    // filter all articles by selected category
    const featuredForCategory = filterByCategory(featured, category)
    const listForCategory = filterByCategory(list, category)

    // select featured article (if exist for category / all choose one
    // at random; otherwise choose most recent one
    let featuredArticle = list[0]
    if (featuredForCategory.length) {
        featuredArticle =
            featuredForCategory[_.random(featuredForCategory.length - 1)]
    } else if (featured.length) {
        featuredArticle = featured[_.random(featured.length - 1)]
    }

    const listOfArticles = listForCategory.filter(
        article => article.url !== featuredArticle?.url
    )
    return { featured: featuredArticle, list: listOfArticles }
}

const filterByCategory = (
    articles: ArticleCardProps[],
    category: string | null
) => {
    if (!category) return articles
    return articles.filter(article => article.tag === category)
}
