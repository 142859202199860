import { navigate, useLocation } from '@reach/router'
import {
    Jumbotron,
    JumbotronProps,
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    ArticleCardProps,
    ArticleFeatured,
    ArticleFilterProps,
    ArticleList,
    Wrapper,
} from 'common/components/sharedLibrary'
import { ArticleFilter } from 'common/components/sharedLibrary/components/src/lib/misc/organisms/article-filter/article-filter'
import { parse } from 'query-string'
import React, { useEffect, useState } from 'react'
import { getArticles } from './utils'

export interface BlogLandingProps {
    articles: {
        featured: ArticleCardProps[]
        list: ArticleCardProps[]
    }
    articleFilter: ArticleFilterProps
    jumbotron: JumbotronProps
    layout: LayoutProps
}

export const BlogLanding: React.FC<BlogLandingProps> = ({
    articles,
    articleFilter,
    jumbotron,
    layout,
}) => {
    const { search } = useLocation()
    const { category: categoryParam } = parse(search)

    useEffect(() => {
        if (categoryParam) setCategory(categoryParam as string)
        else setCategory(null)
    }, [categoryParam])

    const [category, setCategory] = useState<string | null>(null)
    const { featured, list } = getArticles(articles, category)
    const changeCategory = (value: string | null) => {
        setCategory(value)
        navigate(value ? `/blog?category=${value}` : '/blog')
    }

    return (
        <Layout
            {...{
                ...layout,
                footer: {
                    ...layout.footer,
                    regLine: (layout.footer.regLine as any)[category || 'home'],
                    contactForm: (layout.footer.contactForm as any)?.[
                        category || 'home'
                    ],
                },
            }}
        >
            <Jumbotron {...jumbotron} />
            <Wrapper>
                <ArticleFeatured {...featured} />
                <ArticleFilter
                    {...articleFilter}
                    category={category}
                    setCategory={changeCategory}
                />
                <ArticleList articles={list} />
            </Wrapper>
        </Layout>
    )
}
