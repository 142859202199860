import { RouteComponentProps } from '@reach/router'
import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import {
    BlogLanding,
    BlogLandingProps
} from 'common/components/templates/BlogLanding'
import { getArticleData } from 'common/utils/getArticleData'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface BlogLandingPageProps extends RouteComponentProps {
    pageContext: any
}

export const BlogLandingPage: React.FC<BlogLandingPageProps> = ({
    pageContext
}) => {
    return <BlogLanding {...getBlogLandingData(pageContext)} />
}
const buttons = ['Life insurance', 'Health', 'Pensions', 'Investments', 'News', 'Financial wellbeing']
export default BlogLandingPage

export const getBlogLandingData = (data: any): BlogLandingProps => ({
    articles: {
        featured: (data.featured_articles || []).map(getArticleData),
        list: (data.blogPosts || []).map(getArticleData)
    },
    articleFilter: {
        buttons,
        title: data.articlefilter__title,
        subtitle: data.articlefilter__subtitle
    },
    layout: {
        ...getLayoutData(data),
        footer: {
            ...getLayoutData(data).footer,
            variant: 'form'
        }
    },
    jumbotron: getJumbotronData(data)
})
